import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    create: Boolean,
    hideNoResults: Boolean,
    maxItems: Number,
    itemIcon: String,
    tagItem: Boolean,
    newItemBgColor: String
  };
  static classes = ["control"];

  connect() {
    const maxItems = this.hasMaxItemsValue
      ? { maxItems: this.maxItemsValue }
      : {};
    const itemIconValue = this.itemIconValue;
    const hideNoResults = this.hideNoResultsValue;
    const removeButtonClass = this.tagItemValue ? 'white-remove-button' : '';
    const newItemBgColor = this.newItemBgColorValue;
    this.select = new TomSelect(this.element, {
      ...maxItems,
      hidePlaceholder: true,
      create: this.createValue ?? false,
      plugins: {
        remove_button: {
          label: "",
          title: "Remove this item",
          className: `remove ${removeButtonClass}`
        },
      },
      persist: false,
      closeAfterSelect: true,
      onChange: this.onChange.bind(this),
      render: {
        no_results: function (data, escape) {
          return hideNoResults
            ? ""
            : '<div class="no-results p-2">No results found for "' +
                escape(data.input) +
                '"</div>';
        },
        item: function (data, escape) {
          const epicBgColor = data.epicBgColor || newItemBgColor;
          const epicStyle = `background-color: ${escape(epicBgColor)};`;
          const iconHtml = itemIconValue
            ? `<span class="${itemIconValue} icon icon-xs mr-1 text-purple-500"></span>`
            : "";

          if (epicBgColor) {
            return `<div class="item epic-item" style='${escape(epicStyle)}'><span class="truncate">${iconHtml}${escape(data.text)}</span></div>`;
          } else {
            return "<div>" + iconHtml + escape(data.text) + "</div>";
          }
        },
        option: function (data, escape) {
          const { text, disabled, suffixLabel, suffixColorClass } = {
            suffixColorClass: "info",
            ...data,
          };

          const epicBgColor = data.epicBgColor || newItemBgColor;
          const epicClass = epicBgColor ? 'epic-option' : '';
          const epicStyle = epicBgColor ? `background-color: ${escape(epicBgColor)};` : '';
          const defaultOptionTemplate = epicBgColor ?
            `<div><span class="${escape(epicClass)}" style="${escape(epicStyle)}">${escape(text)}</span></div>` :
            `<div>${escape(text)}</div>`;
          const extendedOptionTemplate = `<div class="flex justify-between" style="opacity: 1"><span class="${
            disabled ? "opacity-50" : ""
          }" class="${escape(epicClass)}" style="${escape(epicStyle)}">${escape(
            text
          )}</span><div class="tag ${suffixColorClass}">${escape(
            suffixLabel
          )}</div></div>`;

          return suffixLabel ? extendedOptionTemplate : defaultOptionTemplate;
        },
      },
    });

    this.addMultiClass();

    this.applyControlClass();
  }

  addMultiClass() {
    if (!this.tagItemValue) {
      return;
    }

    const tsControl = this.element.nextElementSibling.querySelector('.ts-control');
    if (tsControl) {
      const tsWrapper = tsControl.parentElement;
      if (tsWrapper && !tsWrapper.classList.contains('multi')) {
        tsWrapper.classList.add('multi');
      }
    }
  }

  applyControlClass() {
    if (this.controlClasses.length > 0) {
      let tsControlDiv =
        this.element.nextElementSibling.querySelector(".ts-control");
      tsControlDiv.classList.add(this.controlClass);
    }
  }

  onChange() {
    if (this.element.dataset.toggleableOption) {
      const [option, targetId] =
        this.element.dataset.toggleableOption.split("#");

      if (this.select.getValue() === option) {
        document.getElementById(targetId).classList.remove("hidden");
      } else {
        document.getElementById(targetId).classList.add("hidden");
      }
    }
  }

  updateOptions(newOptions) {
    this.select.clearOptions();
    newOptions.forEach((option) => {
      this.select.addOption(option);
    });
    this.select.refreshOptions(false);
  }

  removeOption(optionValue) {
    this.select.removeOption(optionValue);
    this.select.refreshOptions(false);
  }

  disconnect() {
    this.select.destroy();
  }
}
